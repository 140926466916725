import React, { useEffect, useState, useCallback, useRef } from 'react';

// helpers
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useStyles } from '../ExaminationTable/ExaminationTable.style';
import * as actions from '../../../../../store/index';
import { formatSimpleDateHour } from '../../../../../helpers/formatDate';

// material-ui
import { useTheme } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  IconButton,
  Collapse,
  CircularProgress,
  Tooltip,
} from '@material-ui/core';

// icons
import LayersIcon from '@material-ui/icons/Layers';
import NotIcon from '@material-ui/icons/NotInterested';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import animation from '../../../../../resources/processing_animation.gif';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import ExaminationTableActions from '../ExaminationTable/Helpers/ExaminationTableActions';
import { examinationTooltips } from '../ExaminationTable/Helpers/ExaminationTableCommon';
import ExaminationTableRisk from '../ExaminationTable/Helpers/ExaminationTableRisk';
import {
  PREMIUM_CANCER_RISK,
  DIAGNOSTICS_MICROCALC,
  DIAGNOSTICS_OPACITIES,
  PREMIUM_DENSITY,
} from '../../../../../config';
import ControlPointIcon from '@material-ui/icons/ControlPoint';

const ExaminationTableRows = (props) => {
  const theme = useTheme();
  const classes = useStyles();
  const currentRowRef = useRef(null);
  const endListRef = useRef(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    localChangesDensity,
    localChangesQuality,
    localChangesMicrocalc,
    localChangesOpacities,
  } = useSelector((state) => state.labeling);
  const {
    currentExam,
    exam_list,
    fetchingExamList,
    pendingReprocessing,
    totalExaminations,
    pagination,
  } = useSelector((state) => state.examinations);
  const [showIcons, setShowIcons] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [expandedRows, setExpandedRows] = useState({});
  const [isHidden, setIsHidden] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsHidden(false);
    }, 6000);
    return () => clearTimeout(timer);
  }, []);

  const handleRowClick = (rowId) => {
    setExpandedRows((prevState) => ({
      ...prevState,
      [rowId]: !prevState[rowId],
    }));
  };

  const handleHover = useCallback((id) => {
    setHoveredIndex(id);
    setShowIcons(true);
  }, []);

  const handleLeave = useCallback(() => {
    setHoveredIndex(null);
    setShowIcons(false);
  }, []);

  useEffect(() => {
    const examsWithCurrentId = props.rows.filter((row) =>
      row.some((exam) => exam.id === currentExam)
    );
    const hasMultipleExams = examsWithCurrentId?.[0]?.length > 1;

    if (currentExam && hasMultipleExams) {
      examsWithCurrentId.forEach((row) => {
        const patientId = row[0]?.patient_id || row[1]?.patient_id;
        if (patientId) {
          setExpandedRows((prevState) => ({
            ...prevState,
            [patientId]: true,
          }));
        }
      });
    }
  }, [props.rows, currentExam]);

  const nestedPatientSelected = (nestedRows) => {
    const examExists = nestedRows.some((row) => row.id === currentExam);
    return examExists;
  };

  const onRowClick = (patId, currentRow) => {
    if (
      localChangesDensity ||
      localChangesQuality ||
      localChangesMicrocalc ||
      localChangesOpacities
    ) {
      dispatch(actions.setActiveDialog('unsavedChanges'));
    } else {
      dispatch(actions.showExamination(patId));
      dispatch(actions.setScrollPosition(currentRow));
    }
  };

  const fetchMorePatients = () => {
    dispatch(actions.setPagination([pagination[0], pagination[1] + 30]));
  };

  const nestedTable = (item, i) => {
    //Nested table
    return (
      <TableRow
        onMouseOver={() => handleHover(item.id)}
        onMouseLeave={handleLeave}
        className={
          item.id === currentExam
            ? classes.tr_nested_hover_selected
            : classes.tr_nested_hover
        }
        hover
        key={item.id}
        selected={item.id === currentExam}
        ref={item.id === currentExam ? currentRowRef : null}
      >
        <TableCell
          className={classes.tr_nested}
          style={{
            padding: '1px !important',
            width: '4%',
          }}
        >
          <div style={{ width: '34px' }} />
        </TableCell>

        {/* Name */}
        <TableCell
          className={classes.tr_nested}
          key="patient_name"
          align="left"
          onClick={() => dispatch(actions.showExamination(item.id))}
          style={{
            fontWeight: item.id === currentExam && 'bold',
            width: '100%',
            marginLeft: '2px',
          }}
        >
          {item.name}
        </TableCell>

        {/* Info */}
        <TableCell
          className={classes.tr_nested}
          align="center"
          key="icons"
          onClick={() => dispatch(actions.showExamination(item.id))}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '30px',
            }}
          >
            {item?.density_confirmed ||
            item?.quality_confirmed ||
            item?.microcalc_confirmed ||
            item?.opacities_confirmed
              ? examinationTooltips(
                  <>
                    {PREMIUM_DENSITY && (
                      <Typography className={classes.tooltip_texts_margin}>
                        {t('ResultView.density')}:
                        {item?.density_confirmed
                          ? t('Table.module_confirmed')
                          : t('Table.module_not_confirmed')}
                      </Typography>
                    )}
                    <Typography className={classes.tooltip_texts_margin}>
                      {t('ResultView.quality')}:
                      {item?.quality_confirmed
                        ? t('Table.module_confirmed')
                        : t('Table.module_not_confirmed')}
                    </Typography>
                    {DIAGNOSTICS_OPACITIES &&
                      localStorage.getItem('diagnostics') === 'true' && (
                        <Typography className={classes.tooltip_texts_margin}>
                          {t('ResultView.diagnose_opacities')}:
                          {item?.opacities_confirmed
                            ? t('Table.module_confirmed')
                            : t('Table.module_not_confirmed')}
                        </Typography>
                      )}
                    {DIAGNOSTICS_MICROCALC &&
                      localStorage.getItem('diagnostics') === 'true' && (
                        <Typography className={classes.tooltip_texts_margin}>
                          {t('ResultView.diagnose_microcalc')}:
                          {item?.microcalc_confirmed
                            ? t('Table.module_confirmed')
                            : t('Table.module_not_confirmed')}
                        </Typography>
                      )}
                  </>,
                  <HowToRegIcon
                    fontSize="inherit"
                    style={{
                      opacity: fetchingExamList && '0.5',
                      // fill: 'rgb(76, 175, 80)',
                    }}
                  />
                )
              : ''}
            {showIcons && item.id === hoveredIndex && (
              <>
                {item?.max_captures_per_projection > 1 &&
                  examinationTooltips(
                    <Typography className={classes.tooltip_texts}>
                      {t('Table.captures')}
                    </Typography>,
                    <LayersIcon fontSize="inherit" />
                  )}
                {!!item?.not_evaluated &&
                  examinationTooltips(
                    <Typography className={classes.tooltip_texts}>
                      {t('Table.not_eval')}
                    </Typography>,
                    <NotIcon fontSize="inherit" />
                  )}
              </>
            )}
          </div>
        </TableCell>

        {/* Date */}
        <TableCell
          className={classes.tr_nested}
          align="center"
          key="date"
          onClick={() => dispatch(actions.showExamination(item.id))}
          style={{
            fontWeight: item.id === currentExam && 'bold',
          }}
        >
          {examinationTooltips(
            formatSimpleDateHour(item.date).hour,
            <Typography
              className={classes.table_date_font}
              style={{
                fontWeight: item.id === currentExam && 'bold',
              }}
            >
              {formatSimpleDateHour(item.date).date}
            </Typography>,
            'top-start'
          )}
        </TableCell>

        {/* Risk (shown or hidden from .env)*/}
        <TableCell
          className={classes.tr_padding_nested}
          key="risk"
          align="right"
          onClick={() => dispatch(actions.showExamination(item.id))}
        >
          {PREMIUM_CANCER_RISK && (
            <ExaminationTableRisk
              exam_id={item.id}
              form_filled={item.form_filled}
              exam_list={exam_list}
            />
          )}
        </TableCell>

        {/* Actions */}
        <ExaminationTableActions
          exam_id={item.id}
          isNestedExam={true}
          nestedRowTitle={false}
        />
      </TableRow>
    );
  };

  return (
    <TableBody style={{ cursor: fetchingExamList ? 'progress' : 'pointer' }}>
      {fetchingExamList && (
        <TableRow
          style={{
            position: 'absolute',
            top: '50%',
            opacity: '0.6',
            width: '99%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <TableCell style={{ border: 'none' }}>
            <img
              style={{
                display: 'block',
                margin: 'auto',
                width: 'auto',
                height: '100px',
                borderRadius: '50%',
              }}
              src={animation}
              alt="logoanimation"
            />
          </TableCell>
        </TableRow>
      )}
      {props.rows.map((row, idx) => {
        const {
          id,
          name,
          date,
          density_confirmed,
          quality_confirmed,
          opacities_confirmed,
          microcalc_confirmed,
          not_evaluated,
          form_filled,
          patient_bd,
          max_captures_per_projection,
          patient_id,
        } = row[0];

        const isRowExpanded = expandedRows[patient_id] || false;
        return (
          <React.Fragment key={idx + name}>
            <TableRow
              key={idx}
              hover
              onMouseOver={() => handleHover(id)}
              onMouseLeave={handleLeave}
              className={classes.tr_hover}
              selected={id === currentExam}
              style={{
                background:
                  isRowExpanded && theme.palette.complementary.highlightLight,
              }}
            >
              {/* Expand icon */}
              <TableCell className={classes.tr_padding}>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => handleRowClick(patient_id)}
                >
                  {row.length > 1 &&
                    (isRowExpanded ? (
                      <ExpandLessIcon fontSize="small" />
                    ) : (
                      <ExpandMoreIcon fontSize="small" />
                    ))}
                </IconButton>
              </TableCell>

              {/* Name */}
              <TableCell
                className={classes.table_cell_container}
                component="th"
                align="left"
                onClick={() => onRowClick(id, props.currentTableRow)}
                style={{ width: '100%', color: fetchingExamList && '#a1a1a1' }}
              >
                {examinationTooltips(
                  <Typography className={classes.tooltip_texts}>
                    {formatSimpleDateHour(patient_bd).date}
                  </Typography>,
                  <Typography
                    className={
                      (!isRowExpanded && id === currentExam) ||
                      (row.length > 1 &&
                        nestedPatientSelected(row) &&
                        !isRowExpanded)
                        ? classes.row_selected
                        : classes.table_font
                    }
                  >
                    {name}
                  </Typography>,
                  'top'
                )}
              </TableCell>

              {/* Info */}
              {row.length > 1 ? (
                <TableCell
                  className={classes.tr_padding}
                  align="right"
                  onClick={() => onRowClick(id, props.currentTableRow)}
                  style={{
                    width: '30px',
                  }}
                />
              ) : (
                <TableCell
                  className={classes.tr_padding}
                  align="right"
                  onClick={() => onRowClick(id, props.currentTableRow)}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      width: '30px',
                    }}
                  >
                    {[
                      density_confirmed,
                      quality_confirmed,
                      opacities_confirmed,
                      microcalc_confirmed,
                    ].some((a) => !!a) &&
                      examinationTooltips(
                        <>
                          {PREMIUM_DENSITY && (
                            <Typography
                              className={classes.tooltip_texts_margin}
                            >
                              {t('ResultView.density')}:
                              {density_confirmed
                                ? t('Table.module_confirmed')
                                : t('Table.module_not_confirmed')}
                            </Typography>
                          )}
                          <Typography className={classes.tooltip_texts_margin}>
                            {t('ResultView.quality')}:
                            {quality_confirmed
                              ? t('Table.module_confirmed')
                              : t('Table.module_not_confirmed')}
                          </Typography>
                          {DIAGNOSTICS_OPACITIES &&
                            localStorage.getItem('diagnostics') === 'true' && (
                              <Typography
                                className={classes.tooltip_texts_margin}
                              >
                                {t('ResultView.diagnose_opacities')}:
                                {opacities_confirmed
                                  ? t('Table.module_confirmed')
                                  : t('Table.module_not_confirmed')}
                              </Typography>
                            )}
                          {DIAGNOSTICS_MICROCALC &&
                            localStorage.getItem('diagnostics') === 'true' && (
                              <Typography
                                className={classes.tooltip_texts_margin}
                              >
                                {t('ResultView.diagnose_microcalc')}:
                                {microcalc_confirmed
                                  ? t('Table.module_confirmed')
                                  : t('Table.module_not_confirmed')}
                              </Typography>
                            )}
                        </>,
                        <HowToRegIcon
                          fontSize="inherit"
                          style={{
                            opacity: fetchingExamList && '0.5',
                            // fill: 'rgb(76, 175, 80)',
                          }}
                        />
                      )}
                    {showIcons && id === hoveredIndex && (
                      <>
                        {max_captures_per_projection > 1 &&
                          examinationTooltips(
                            <Typography className={classes.tooltip_texts}>
                              {t('Table.captures')}
                            </Typography>,
                            <LayersIcon
                              fontSize="inherit"
                              style={{ opacity: fetchingExamList && '0.5' }}
                            />
                          )}
                        {!!not_evaluated &&
                          examinationTooltips(
                            <Typography className={classes.tooltip_texts}>
                              {t('Table.not_eval')}
                            </Typography>,
                            <NotIcon
                              fontSize="inherit"
                              style={{ opacity: fetchingExamList && '0.5' }}
                            />
                          )}
                      </>
                    )}
                  </div>
                </TableCell>
              )}

              {/* Date */}
              {isRowExpanded ? (
                <TableCell
                  className={classes.table_cell_container}
                  align="center"
                />
              ) : (
                <TableCell
                  className={classes.table_cell_container}
                  align="center"
                  onClick={() => onRowClick(id, props.currentTableRow)}
                  style={{ color: fetchingExamList && '#a1a1a1' }}
                >
                  {examinationTooltips(
                    <Typography className={classes.tooltip_texts}>
                      {formatSimpleDateHour(date).hour}
                    </Typography>,
                    <Typography
                      className={
                        id === currentExam || nestedPatientSelected(row)
                          ? classes.row_selected
                          : classes.table_date_font
                      }
                    >
                      {formatSimpleDateHour(date).date}
                    </Typography>,
                    'top-start'
                  )}
                </TableCell>
              )}

              {/* Risk */}
              {isRowExpanded ? (
                <TableCell className={classes.tr_padding} align="center" />
              ) : (
                <TableCell
                  className={classes.tr_padding}
                  align="center"
                  onClick={() => onRowClick(id, props.currentTableRow)}
                >
                  {PREMIUM_CANCER_RISK && (
                    <ExaminationTableRisk
                      exam_id={id}
                      currentExam={currentExam}
                      form_filled={form_filled}
                    />
                  )}
                </TableCell>
              )}

              {/* Actions */}
              <ExaminationTableActions
                exam_id={id}
                isNestedExam={false}
                nestedRowTitle={isRowExpanded && row.length > 1}
              />
            </TableRow>

            {/* Nested examinations row container */}
            {row.length > 1 && (
              <TableRow>
                <TableCell
                  style={{
                    padding: '0',
                    backgroundColor:
                      theme.palette.complementary.highlightDarker,
                  }}
                  colSpan={6}
                >
                  <Collapse in={isRowExpanded} timeout="auto" unmountOnExit>
                    <Table size="small" aria-label="nestedTable">
                      <TableBody>
                        {row.map((item, i) => nestedTable(item, i))}
                      </TableBody>
                    </Table>
                  </Collapse>
                </TableCell>
              </TableRow>
            )}
          </React.Fragment>
        );
      })}

      <TableRow>
        {fetchingExamList ? (
          <TableCell
            colSpan={6}
            align="center"
            style={{ borderBottom: 'none', opacity: '0.6' }}
          >
            <CircularProgress color="primary" size={14} />
          </TableCell>
        ) : (
          <TableCell
            colSpan={6}
            ref={endListRef}
            align="center"
            style={{
              borderBottom: 'none',
              borderTop: '4px solid rgba(81, 81, 81, 1)',
              height: '20px',
            }}
          >
            {exam_list.length < totalExaminations && !fetchingExamList && (
              <Tooltip title="Fetch more examinations" placement="bottom" arrow>
                <span>
                  <IconButton
                    size="small"
                    onClick={() => fetchMorePatients()}
                    className={isHidden ? classes.hidden : ''}
                  >
                    <ControlPointIcon color="disabled" fontSize="small" />
                  </IconButton>
                </span>
              </Tooltip>
            )}
          </TableCell>
        )}
      </TableRow>
    </TableBody>
  );
};

export default ExaminationTableRows;
